import "../stylesheets/common.scss";

window.addEventListener("load", () => {
  /*===========================================================*/
  /* Hamburger menu */
  /*===========================================================*/
  // Status of menuOpen
  let is_menuOpen = false;

  // Manipulate class name status with the click of a button
  document
    .querySelector(".p-header-sp-nav__btn")
    .addEventListener("click", function () {
      document.querySelector(".p-header-sp-nav").classList.toggle("is-active");
      document
        .querySelector(".p-header-sp-nav__btn")
        .classList.toggle("is-active");

      // Scroll prohibition is removed when the menu is open on click
      if (is_menuOpen) {
        // PC
        document.removeEventListener("wheel", disableScroll, {
          passive: false,
        });
        // SP
        document.removeEventListener("touchmove", disableScroll, {
          passive: false,
        });

        is_menuOpen = false;

        // Scrolling is prohibited when the menu is closed on click
      } else {
        // PC用
        document.addEventListener("wheel", disableScroll, { passive: false });
        // スマホ用
        document.addEventListener("touchmove", disableScroll, {
          passive: false,
        });

        is_menuOpen = true;
      }
    });

  // Hide the SP menu when clicking on links in the menu
  const linkButton = document.querySelectorAll(".p-header-sp-nav a");
  const linkButtonArray = Array.from(linkButton);

  linkButtonArray.forEach(function (element) {
    element.addEventListener("click", function () {
      document.querySelector(".p-header-sp-nav").classList.toggle("is-active");
      document
        .querySelector(".p-header-sp-nav__btn")
        .classList.toggle("is-active");
    });
  });

  const anchorLinks = document.querySelectorAll("a"); // すべてのアンカーリンクを取得

  anchorLinks.forEach(function (link) {
    link.addEventListener("click", function (event) {
      // PC
      document.removeEventListener("wheel", disableScroll, {
        passive: false,
      });
      // SP
      document.removeEventListener("touchmove", disableScroll, {
        passive: false,
      });
    });
  });

  /*===========================================================*/
  /* Function to prohibit scrolling */
  /*===========================================================*/
  const disableScroll = (event) => {
    event.preventDefault();
  };
});

// スクロールすると画面の最上部に戻るボタンを表示
window.addEventListener("scroll", () => {
  const scrollTop = document.documentElement.scrollTop;

  const scrollTopBtn = document.getElementById("js-scrollTopBtn");

  if (scrollTop > 200) {
    scrollTopBtn.classList.add("is-show");
  } else {
    scrollTopBtn.classList.remove("is-show");
  }
});

document.addEventListener("DOMContentLoaded", function () {
  // アンカーリンクのクリックイベントをキャッチ
  const anchorLinks = document.querySelectorAll('a[href*="#"]');

  // anchorLinks.forEach(function (link) {
  for (let i = 0; i < anchorLinks.length; i++) {
    anchorLinks[i].addEventListener("click", function (e) {
      e.preventDefault(); // リンクのデフォルトの動作を無効化

      // クリックされたアンカーリンクの href 属性値を取得
      const hrefValue = this.getAttribute("href");
      let targetId;

      // hrefValueが#から始まる場合
      if (hrefValue.charAt(0) === "#") {
        // #を除いた値を取得
        targetId = hrefValue.substring(1);

      } else {
        const parts = hrefValue.split("#");

        // 現在のURLを取得する
        let currentURL = location.href;
        currentURL = currentURL.split("#")[0];

        let linkURL = parts[0];

        // もし最後にスラッシュがある場合は削除する
        if (currentURL.slice(-1) === "/") {
          currentURL = currentURL.slice(0, -1);
        }

        if (linkURL.slice(-1) === "/") {
          linkURL = linkURL.slice(0, -1);
        }

        targetId = parts[parts.length - 1];

        if (currentURL !== linkURL) {
          window.location.href = linkURL + "#" + targetId;
          return;
        }
      }

      // スクロール先の要素を取得
      const targetElement = document.getElementById(targetId);

      if (targetElement) {
        // スクロールアニメーションのオプションを設定
        const options = {
          behavior: "smooth", // スムーズなスクロールを有効にする
          block: "start", // スクロール先の要素の開始位置に合わせる
        };

        // スクロールを実行
        targetElement.scrollIntoView(options);
      }
    });
  }
});
